import React from 'react';
import { Form, Text } from 'react-form';

import './style.css';
import Button from '../../components/Button';

const errorValidator = (values) => {
  return {
    email: !values.email,
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;

const PasswordReset = ({ back, resetPassword }) =>
  <div className="Dashboard LoginPage container">
    <Button onClick={back} secondary small>&larr; Back to login</Button>
    <div className="center-xs pd-v-20 pd-h-10">
      <h1 className="title">Reset Your Password</h1>
    </div>
    <Form
      validateError={errorValidator}
      onSubmit={resetPassword}
    >
      { (formApi) => (
        <form id="login" className="pd-h-15" onSubmit={formApi.submitForm}>
          <div className="row">
            <div className="KS_input col-xs-12">
              <label htmlFor="email">Email</label>
              <Text className={shouldShowError('email', formApi) ? 'error' : ''} field="email" id="email"/>
            </div>
          </div>
          <div className="row center-xs pd-15">
            <Button type="submit">Reset Password</Button>
          </div>
        </form>
      )}
    </Form>
  </div>;

export default PasswordReset;
