import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Text } from 'react-form';

import { guestSignup } from '../../actions/guestSignup';

import Button from '../Button';

import './style.css';

const errorValidator = (values) => {
  return {
    first_name: !values.first_name,
    last_name: !values.last_name,
    email: !values.email,
    company_name: !values.company_name && !values.invite_code,
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;


const GuestForm = ({className, cta, back, signup, mac, site, ap, url}) =>
  <div className="guest__form">
    <Form
      validateError={errorValidator}
      onSubmit={signup}
      defaultValues={{
        mac,
        site,
        ap,
        url,
      }}
    >
      { (formApi) => (
        <form id="signup" className={className} onSubmit={formApi.submitForm}>
          {
            back && <Button type="button" onClick={back} secondary small>&larr; Back to login</Button>
          }
          <div className="center-xs pd-5">
            <div className="guest__form--hed">
              Here to See Someone?
            </div>
          </div>
          <div className="row">
            <div className="KS_input col-xs-12 col-sm-6">
              <label className="guest__form--label" htmlFor="first_name">First Name</label>
              <Text className={shouldShowError('first_name', formApi) ? 'error' : ''} field="first_name"
                    id="first_name"/>
            </div>
            <div className="KS_input col-xs-12 col-sm-6">
              <label className="guest__form--label" htmlFor="last_name">Last Name</label>
              <Text className={shouldShowError('last_name', formApi) ? 'error' : ''} field="last_name"
                    id="last_name"/>
            </div>
          </div>
          <div className="row">
            <div className="KS_input col-xs-12 col-sm-6">
              <label className="guest__form--label" htmlFor="email">Email Address</label>
              <Text className={shouldShowError('email', formApi) ? 'error' : ''} type="email" field="email" id="email"/>
            </div>
            <div className="KS_input col-xs-12 col-sm-6">
              <label className="guest__form--label" htmlFor="company_name">Company</label>
              <Text className={shouldShowError('company_name', formApi) ? 'error' : ''} field="company_name"
                    id="company_name"/>
            </div>
          </div>
          <div className="row">
            <div className="KS_input col-xs-12 col-sm-12">
              <label className="guest__form--label" htmlFor="here_to_see">Who are you here to see? Please enter their email address.</label>
              <Text className={shouldShowError('here_to_see', formApi) ? 'error' : ''} type="email" field="here_to_see"
                    id="here_to_see"/>
            </div>
          </div>
          <div className="row center-xs pd-20">
            <Button secondary className="guest__form--cta" type="submit">{ cta }</Button>
          </div>
          <Text type="hidden" field="mac" id="mac" value={mac}/>
          <Text type="hidden" field="site" id="site" value={site}/>
          <Text type="hidden" field="ap" id="ap" value={ap}/>
          <Text type="hidden" field="url" id="url" value={url}/>
        </form>
      )}
    </Form>
  </div>;

GuestForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  cta: PropTypes.string,
};

GuestForm.defaultProps = {
  className: '',
  onSubmit: () => null,
  cta: 'Connect',
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: formValues => dispatch(guestSignup(formValues)),
  }
};

export default connect(null, mapDispatchToProps)(GuestForm);
