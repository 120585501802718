import React from 'react';
import { Form, Text } from 'react-form';
import Button from '../Button';
import Notification from '../Notification';

import logo from '../../images/logo.png';

import './style.css';

const errorValidator = (values) => {
  return {
    email: !values.email,
    password: !values.password,
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;

const LoginForm = ({onSubmit, mac, site, ap, url, select, back, guest}) => (
  <div className="login__form">
    <div className="center-xs pd-5">
      { guest
        ? <div className="guest__pass--hed">Already Have a Guest Pass?</div>
        : <img className="logo" alt="KettleSpace" src={logo} />
      }
    </div>
    <Form
      validateError={errorValidator}
      onSubmit={onSubmit}
      defaultValues={{
        mac,
        site,
        ap,
        url,
      }}
    >
      { (formApi) => (
        <form id="login" className="pd-h-15" onSubmit={formApi.submitForm}>
          <div className="row">
            <div className="KS_input col-xs-12">
              <label htmlFor="email">Email</label>
              <Text type="email" className={shouldShowError('email', formApi) ? 'error' : ''} field="email" id="email"/>
            </div>
          </div>
          <div className="row">
            <div className="KS_input col-xs-12">
              <label htmlFor="password">Password</label>
              <Text type="password" className={shouldShowError('password', formApi) ? 'error' : ''} field="password" id="password"/>
            </div>
          </div>
          <div className="row center-xs pd-15">
            <Button type="submit">Connect</Button>
            { guest
              ? <Button className="mg-t-15" type="button" onClick={back} secondary small>&larr; Back to login</Button>
              : <a className="mg-t-15" onClick={() => select('password-reset')}>Forgot your password?</a>
            }
          </div>
          <Text type="hidden" field="mac" id="mac" value={mac}/>
          <Text type="hidden" field="site" id="site" value={site}/>
          <Text type="hidden" field="ap" id="ap" value={ap}/>
          <Text type="hidden" field="url" id="url" value={url}/>
        </form>
      )}
    </Form>
    <Notification />
  </div>
);

export default LoginForm;
