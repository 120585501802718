import axios from 'axios';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  sendSuccessNotification,
  sendErrorNotification,
  setPhoneNumber,
  hidePhoneNumberForm,
  selectPage,
} from './app';

import phoneNumber from '../helpers/phoneNumber';

export function verifyNumber({ code }) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    const { token } = getState().app;

    axios.request({
      url: '/verify-account',
      method: 'POST',
      data: {
        token,
        code,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(hideLoading());

        dispatch(selectPage('login'));
        dispatch(sendSuccessNotification('Your account is now verified! Log in to gain access.'));
      }).catch((err) => {
        dispatch(hideLoading());

        const errMsg = err.response ? err.response.data.message : 'Verification failed! Try again';
        dispatch(sendErrorNotification(errMsg));
      });
  };
}

export function resendCode() {
  return (dispatch, getState) => {
    dispatch(showLoading());

    const { token } = getState().app;

    axios.request({
      url: '/resend-code',
      method: 'POST',
      data: {
        token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(hideLoading());
        dispatch(sendSuccessNotification('Check your phone for your new code.'));
      }).catch((err) => {
        dispatch(hideLoading());

        const errMsg = err.response ? err.response.data.message : 'Code resend failed! Try again';
        dispatch(sendErrorNotification(errMsg));
      });
  };
}

export function changePhoneNumber({ phone }) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    const { token } = getState().app;

    axios.request({
      url: '/reset-phone-number',
      method: 'POST',
      data: {
        token,
        phone: phoneNumber.format(phone),
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(hideLoading());

        dispatch(setPhoneNumber(response.data.phone));
        dispatch(hidePhoneNumberForm());

        dispatch(sendSuccessNotification('We have sent a new code to your new number.'));
      }).catch((err) => {
        dispatch(hideLoading());

        const errMsg = err.response ? err.response.data.message : 'Phone number change failed! Try again';
        dispatch(sendErrorNotification(errMsg));
      });
  };
}
