import React from 'react';
import { Form, Text } from 'react-form';

import './style.css';
import Button from '../../components/Button';

const errorValidator = (values) => {
  return {
    email: !values.email,
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;

const Notice = ({ back, resetPassword }) =>
  <div className="Dashboard container row">
    <div className="col-xs-3">
      <Button onClick={back} secondary small>&larr; Back to login</Button>
    </div>
    <div className="col-xs-9 pd-v-10">
      <h1 className="title mg-b-40">Our Response to COVID-19</h1>
      <p>Test</p>
      <p>Test</p>
      <p>Test</p>
      <p>Test</p>
      <p>Test</p>
    </div>
  </div>;

export default Notice;
