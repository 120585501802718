import React, { Component } from 'react';
import { connect } from 'react-redux';

import './style.css';

import { setAppCueAsRead } from '../../actions/app';
import qrCodeIos from '../../images/qr-code-ios.png';
import appStore from '../../images/app-store-icon.svg';


class Nudge extends Component {
  constructor(props) {
    super(props);

    const { shouldShowModal } = this.props;

    this.state = {
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.props.readAppCue('IOS_APP');
    this.setState({
      open: false,
    });
  }

  openModal() {
    this.setState({
      open: true,
    });
  }

  render() {
    const { open } = this.state;
    const { closeModal, openModal } = this;

    return (
      <div>
        { open
          ? <div className="Nudge">
            <div className="nudge__content">
              <div className="nudge__exit-container">
                <svg
                  onClick={closeModal}
                  xmlns="http://www.w3.org/2000/svg"
                  className="nudge__exit-icon"
                  width="24" height="24" viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
                </svg>
              </div>
              <div className="nudge__header">
                KettleSpace for iOS Is Here
              </div>
              <div className="nudge__cta">
                <img className="nudge__qr-code" src={qrCodeIos} alt="QR Code - KettleSpace for iOS" />
              </div>
              <div className="nudge__body">
                Use your iPhone camera to scan and download, or search for KettleSpace in the App Store. We're very excited to share this with you!
              </div>
            </div>
          </div>
          : null
        }
        <div className="FixedNudge">
          <div className="nudge__content" onClick={openModal}>
            KettleSpace for iOS is here.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  readAppCue: appCueName => dispatch(setAppCueAsRead(appCueName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nudge);
