import axios from 'axios';
import Cookies from 'js-cookie';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const noop = function noop() {};

const scrollIntoView = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) element.scrollIntoView();
};

export function selectPage(page) {
  return {
    type: 'SELECT_PAGE',
    payload: page,
  };
}

export function dismissNotification() {
  return {
    type: 'UPDATE_NOTIFICATION',
    payload: {
      show: false,
    },
  };
}

function updateNotification(notification) {
  return {
    type: 'UPDATE_NOTIFICATION',
    payload: notification,
  };
}

export function sendSuccessNotification(msg) {
  return (dispatch) => {
    dispatch(updateNotification({
      show: true,
      type: 'success',
      message: msg,
    }));
    scrollIntoView('KS-notification');
    setTimeout(() => {
      dispatch(dismissNotification());
    }, 8000);
  };
}

export function sendErrorNotification(msg) {
  return (dispatch) => {
    dispatch(updateNotification({
      show: true,
      type: 'error',
      message: msg,
    }));
    scrollIntoView('KS-notification');
    setTimeout(() => {
      dispatch(dismissNotification());
    }, 8000);
  };
}

export function showPhoneNumberForm() {
  return {
    type: 'SET_NUMBER_CHANGE_ATTEMPT',
  };
}

export function hidePhoneNumberForm() {
  return {
    type: 'RESET_NUMBER_CHANGE_ATTEMPT',
  };
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: 'SET_NUMBER',
    payload: phoneNumber,
  };
}

export function toggleNav() {
  return {
    type: 'TOGGLE_NAV',
  };
}

function appInitialized() {
  return {
    type: 'APP_INITIALIZED',
    payload: true,
  };
}


export function updateJwt(token) {
  return {
    type: 'UPDATE_JWT',
    payload: token,
  };
}

function updateAppCues(appCues = {}) {
  let appCuesObject;

  try {
    appCuesObject = JSON.parse(appCues);
  } catch (e) {
    appCuesObject = {};
  }

  return {
    type: 'APP.UPDATE_APP_CUES',
    payload: appCuesObject,
  };
}

export function setAppCueAsRead(appCueName) {
  return (dispatch, getState) => {
    const { appCues } = getState().app;

    const newAppCuesData = Object.assign({}, appCues, {
      [appCueName]: true,
    });

    Cookies.set('app-cues', JSON.stringify(newAppCuesData), { expires: 30 });
  };
}

export function authorize(email, password, mac, site, ap) {
  return (dispatch) => {
    dispatch(showLoading());

    axios.request({
      url: '/authorize',
      method: 'POST',
      data: {
        email,
        password,
        mac,
        site,
        ap,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const {
          trialStarted,
          isMember,
          locationUrl,
        } = response.data;

        const fbq = window.fbq || noop;

        // Facebook trial start conversion tracking
        if (trialStarted) {
          fbq('track', 'ViewContent', {
            content_name: 'TrialStarted',
          });
          console.log('called fbq("track", "ViewContent")');
        }

        dispatch(hideLoading());
        dispatch(sendSuccessNotification('Login successful! Redirecting in 2 seconds'));

        // const base = locationUrl || 'https://www.kettlespace.com/';

        // 7.30.2020 -- for COVID return to work purposes, we will
        // redirect users to the COVID safety guidelines page
        const base = 'https://www.kettlespace.com/safety';

        const checkin = 'checkin=1';
        const newTrial = trialStarted ? 'trialStarted=1' : 'trialStarted=';
        const member = isMember ? 'member=1' : 'member=';
        const redirect = `${base}?${checkin}&${newTrial}&${member}`;

        setTimeout(() => {
          window.location.replace(redirect); // eslint-disable-line
        }, 2000);
      })
      .catch((err) => {
        dispatch(hideLoading());

        const { response } = err;

        // ERROR: PHONE IS NOT VERIFIED.
        if (response && response.data && response.data.verified === false) {
          dispatch(updateJwt(response.data.token));
          dispatch(setPhoneNumber(response.data.phone));

          dispatch(selectPage('verify-phone'));

          // if no phone number found for user
          if (!response.data.phone) {
            dispatch(showPhoneNumberForm());
          }
        // GENERIC ERROR
        } else {
          dispatch(sendErrorNotification(err.response.data.message));
          dispatch(appInitialized());
        }
      });
  };
}

export function authorizeGuestPass(email, password, mac, site, ap) {
  return (dispatch) => {
    dispatch(showLoading());

    axios.request({
      url: '/authorize-guest-pass',
      method: 'POST',
      data: {
        email,
        password,
        mac,
        site,
        ap,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const {
          locationUrl,
        } = response.data;

        dispatch(hideLoading());
        dispatch(sendSuccessNotification('Login successful! Redirecting in 2 seconds'));

        const base = locationUrl || 'https://www.kettlespace.com/';

        const checkin = 'checkin=true';
        const redirect = `${base}?${checkin}`;

        setTimeout(() => {
          window.location.replace(redirect); // eslint-disable-line
        }, 2000);
      })
      .catch(() => {
        dispatch(hideLoading());

        dispatch(sendErrorNotification('Could not find your day pass. Please check your email/password.'));
        dispatch(appInitialized());
      });
  };
}

export function initiatePasswordReset(email) {
  return (dispatch) => {
    dispatch(showLoading());
    return axios.request({
      url: '/reset-password',
      method: 'POST',
      data: {
        email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(hideLoading());
        dispatch(sendSuccessNotification('Check your email!'));
      })
      .catch((error) => {
        dispatch(hideLoading());
        if (error.response && error.response.status === 404) {
          dispatch(sendErrorNotification('No user with that email'));
        } else {
          dispatch(sendErrorNotification('Password reset failed! Try again'));
        }
      });
  };
}

export function initializeApp() {
  return (dispatch) => {
    const jwt = Cookies.get('jwt') || null;
    const appCues = Cookies.get('app-cues') || null; // for in-app announcements

    if (jwt) {
      dispatch(updateJwt(jwt));
      dispatch(appInitialized());
    } else {
      dispatch(appInitialized());
    }

    dispatch(updateAppCues(appCues));
  };
}
