import 'babel-polyfill';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';

import { ConnectedRouter, routerReducer, routerMiddleware } from 'react-router-redux';

import './index.css';

// Add pages
import LoginPage from './screens/LoginPage';
// Add Notification System
import LoadingBar, { loadingBarReducer } from 'react-redux-loading-bar';
// Add App Cues
import Nudge from './components/Nudge';

// Import the reducers
import * as reducers from './reducers';

import * as appActions from './actions/app';

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer,
    loadingBar: loadingBarReducer
  }),
  compose(
    applyMiddleware(thunk),
    applyMiddleware(middleware)
  )
);

class Index {
  constructor() {
    this.isInitialized = false;
    this.isLoggedIn = false;
    store.subscribe(this.onStoreUpdate.bind(this));
    store.dispatch(appActions.initializeApp());
  }

  onStoreUpdate() {
    const { isInitialized } = store.getState().app;
    if (this.isInitialized !== isInitialized) {
      this.isInitialized = isInitialized;

      // start the app
      this.startApp();
    }
  }

  startApp() {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <LoadingBar style={{zIndex: 9999, backgroundColor: '#006090', position: 'fixed'}} updateTime={50} />
            <div className="Wifi-portal">
              <Switch>
                <Route exact path="/" component={LoginPage}/>
                <Route path="/guest/s/:site" component={LoginPage}/>
              </Switch>
            </div>
          </div>
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
}
new Index();
