import React from 'react';
import PropTypes from 'prop-types';
import { Form, Text, Select, Checkbox } from 'react-form';

import Button from '../Button';
import Notification from '../Notification';

const stepOneErrorValidator = (values) => {
  return {
    first_name: !values.first_name,
    last_name: !values.last_name,
    email: !values.email,
    company_name: !values.company_name && !values.invite_code,
    channel: !values.channel && !values.invite_code,
    password: !values.password || values.password.length < 5,
    confirm_password: !values.confirm_password || (values.password !== values.confirm_password),
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;

const channelOptions = [
  {
    label: 'Facebook/Instagram',
    value: 'facebook_instagram',
  },
  {
    label: 'Google Search',
    value: 'google',
  },
  {
    label: 'I saw your sign on the street',
    value: 'street_sign',
  },
  {
    label: 'Word of mouth/friend told me',
    value: 'word_of_mouth',
  },
  {
    label: 'I came in for an event',
    value: 'event',
  },
  {
    label: 'Media coverage/press',
    value: 'press',
  },
  {
    label: 'Email promotion',
    value: 'email_promotion',
  },
  {
    label: 'TV Commercial',
    value: 'tv_commercial',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const promotionOptions = [
  {
    label: 'Spacious',
    value: 'spacious',
  },
  {
    label: 'WeWork',
    value: 'wework',
  },
  {
    label: 'Another coworking space',
    value: 'coworking',
  },
  {
    label: 'Company office',
    value: 'office',
  },
  {
    label: 'Coffeeshop',
    value: 'coffeeshop',
  },
  {
    label: 'I work from home',
    value: 'home',
  },
  {
    label: 'Other',
    value: 'other',
  },
];


const StepOne = ({ className, back, signup, mac, site, ap, url }) => {
  return (
    <div>
      <Form
        validateError={stepOneErrorValidator}
        onSubmit={signup}
        defaultValues={{
          mac,
          site,
          ap,
          url,
        }}
      >
        { (formApi) => (
          <form id="signup-1" className={className} onSubmit={formApi.submitForm}>
            <Button type="button" onClick={back} secondary small>&larr; Back to login</Button>
            <div className="row">
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="first_name">First Name</label>
                <Text className={shouldShowError('first_name', formApi) ? 'error' : ''} field="first_name"
                      id="first_name"/>
              </div>
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="last_name">Last Name</label>
                <Text className={shouldShowError('last_name', formApi) ? 'error' : ''} field="last_name"
                      id="last_name"/>
              </div>
            </div>
            <div className="row">
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="email">Email Address</label>
                <Text className={shouldShowError('email', formApi) ? 'error' : ''} field="email" id="email"/>
              </div>
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="company_name">Company</label>
                <Text className={shouldShowError('company_name', formApi) ? 'error' : ''} field="company_name"
                      id="company_name"/>
              </div>
            </div>
            <div className="row">
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="password">Password</label>
                <Text className={shouldShowError('password', formApi) ? 'error' : ''} type="password" field="password"
                      id="password"/>
              </div>
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="confirm_password">Confirm Password</label>
                <Text className={shouldShowError('confirm_password', formApi) ? 'error' : ''} type="password"
                      field="confirm_password" id="confirm_password"/>
              </div>
            </div>
            <div className="row">
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="channel">How did you hear about us?</label>
                <div className="select-box">
                  <Select field="channel" id="channel" options={channelOptions} />
                </div>
              </div>
              <div className="KS_input col-xs-12 col-sm-6">
                <label htmlFor="promotion">Where are you coming from?</label>
                <div className="select-box">
                  <Select field="promotion" id="promotion" options={promotionOptions} />
                </div>
              </div>
            </div>
            <div className="row center-xs pd-20">
              <Button type="submit">Start Trial</Button>
            </div>
            <Text type="hidden" field="mac" id="mac" value={mac}/>
            <Text type="hidden" field="site" id="site" value={site}/>
            <Text type="hidden" field="ap" id="ap" value={ap}/>
            <Text type="hidden" field="url" id="url" value={url}/>
          </form>
        )}
      </Form>
      <Notification />
    </div>
  );
};


StepOne.propTypes = {
  className: PropTypes.string,
  back: PropTypes.func,
  signup: PropTypes.func,
};

StepOne.defaultProps = {
  className: '',
  back: () => null,
  signup: () => null,
};


export default StepOne;
