import axios from 'axios';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { authorize, sendErrorNotification } from './app';
import phoneNumber from '../helpers/phoneNumber';

const noop = function noop() {};

export default function signup(formValues) {
  return (dispatch) => {
    dispatch(showLoading());

    const {
      first_name,
      last_name,
      email,
      phone,
      password,
      company_name,
      channel,
      mac,
      site,
      ap,
      url,
      promotion,
    } = formValues;

    const attribution = promotion ? { promotion } : null;

    const signupData = {
      first_name,
      last_name,
      email,
      phone: phoneNumber.format(phone),
      password,
      company_name,
      channel,
      attribution,
      mac,
      site,
      ap,
    };

    return axios.request({
      url: '/trial-signup',
      method: 'POST',
      data: signupData,
    })
      .then(() => {
        const fbq = window.fbq || noop;

        // Facebook trial signup conversion tracking
        fbq('track', 'Lead', {
          content_name: 'Trial SignUp',
        });
        console.log('called fbq("track", "Lead")');

        dispatch(hideLoading());
        dispatch(authorize(email, password, mac, site, ap, url));
      })
      .catch((err) => {
        const errMsg = err.response ? err.response.data.message : 'Sign Up Failed! Try again';
        dispatch(hideLoading());
        dispatch(sendErrorNotification(errMsg));
      });
  };
}
