import React from 'react';
import { connect } from 'react-redux';

import './style.css';

import { dismissNotification } from '../../actions/app';

const Notification = ({ notification, dismiss }) => {
  const className = (notification.type || '') + (notification.show ? ' slide-in' : ' slide-out');
  return (
    <div id="KS-notification" className={`Notification ${className}`}>
      <button onClick={dismiss}>
        <p>{notification.message}</p>
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismiss: () => {
      dispatch(dismissNotification());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    notification: state.app.notification
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
