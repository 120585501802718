import { parse, format as Format, isValidNumber } from 'libphonenumber-js';

const supportedCountries = new Set(['US', 'PR']);

const validate = (number) => {
  for (const country of supportedCountries) {
    if (isValidNumber(number, country)) {
      return true;
    }
  }

  return false;
};

const getCountryCode = (number) => {
  for (const country of supportedCountries) {
    if (isValidNumber(number, country)) {
      return country;
    }
  }

  return null;
};

const format = (number) => {
  const countryCode = getCountryCode(number);
  const parsedNumber = parse(number, countryCode);

  return Format(parsedNumber, 'E.164');
};

const stripCountryCode = (number) => {
  const parsedNumber = parse(number, 'US');
  if (parsedNumber.country === 'US') return parsedNumber.phone;
  return number;
};

const phoneNumber = {
  validate,
  format,
  stripCountryCode,
};

export default phoneNumber;
