import React from 'react';
import PropTypes from 'prop-types';
import { Form, Text } from 'react-form';
import phoneNumber from '../../helpers/phoneNumber';

import Button from '../Button';
import Notification from '../Notification';

const stepTwoErrorValidator = (values) => {
  return {
    phone: !values.phone || !phoneNumber.validate(values.phone),
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;


const StepTwo = ({ className, back, signup, mac, site, ap, url }) => {
  return (
    <div className="trial">
      <Form
        validateError={stepTwoErrorValidator}
        onSubmit={signup}
      >
        { (formApi) => (
          <form id="signup-2" className={className} onSubmit={formApi.submitForm}>
            <Button type="button" onClick={back} secondary small>&larr; Back to login</Button>
            <div className="center-xs row">
              <h1 className="trial__hed">Please Enter Your Phone Number to Verify Your Account</h1>
            </div>
            <div className="row">
              <div className="KS_input trial__phone col-xs-12 col-sm-6">
                <label htmlFor="phone">Phone Number</label>
                <Text type="tel" className={shouldShowError('phone', formApi) ? 'error' : ''} field="phone" id="phone"/>
              </div>
            </div>
            <Notification />
            <div className="row center-xs pd-20">
              <Button type="submit">Send Code</Button>
            </div>
          </form>
        )}
      </Form>
      <div className="row">
        <div className="trial__subsection col-xs-10 col-sm-6">
          <p className="trial__copy">
            We need your phone number to secure your account. We never share your number with 3rd parties.
          </p>
        </div>
      </div>
    </div>
  );
}


StepTwo.propTypes = {
  className: PropTypes.string,
  back: PropTypes.func,
  signup: PropTypes.func,
};

StepTwo.defaultProps = {
  className: '',
  back: () => null,
  signup: () => null,
};

export default StepTwo;
