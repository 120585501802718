import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Text, Select } from 'react-form';
import phoneNumber from '../../helpers/phoneNumber';

import { verifyNumber, resendCode, changePhoneNumber } from '../../actions/verify';
import { showPhoneNumberForm } from '../../actions/app';

import Button from '../Button';
import Notification from '../Notification';

const errorValidator = (values) => ({
  code: !values.code || values.code.length !== 6,
});

const phoneErrorValidator = (values) => {
  return {
    phone: !values.phone || !phoneNumber.validate(values.phone),
  };
};

const shouldShowError = (field, formApi) => formApi.errors[field] && formApi.submits > 0;

const obfuscatePhoneNumber = number => `******${number.slice(-4)}`;

const PhoneVerification = ({ className, back, verify, resend, phoneNumber, isChangingNumber, changePhoneNumber, showPhoneNumberForm }) => {

  return (
    <div className="PhoneVerification">
      {
        phoneNumber &&
        <Form
          validateError={errorValidator}
          onSubmit={verify}
        >
          { (formApi) => (
            <form id="verify" className={className} onSubmit={formApi.submitForm}>
              <Button type="button" onClick={back} secondary small>&larr; Back to login</Button>
              <div className="row">
                <h1 className="verification__hed"> Please Verify Your Account </h1>
              </div>
              <Notification />
              <div className="row">
                <div className="KS_input verification__input col-xs-8 col-sm-4">
                  <Text className={shouldShowError('code', formApi) ? 'error' : ''} field="code"
                        id="code" placeholder="Enter your code"/>
                </div>
              </div>
              <div className="row center-xs pd-15">
                <Button type="submit">Verify</Button>
              </div>
            </form>
          )}
        </Form>
      }
      {
        phoneNumber &&
        <div className="row">
          <div className="verification__subsection col-xs-10 col-sm-6">
            <p className="verification__copy">
              We just sent an SMS message to <strong>{obfuscatePhoneNumber(phoneNumber)}</strong> with your authentication code.
            </p>
            <p className="verification__copy">
              Please enter the code above to verify your account and enjoy access to our spaces. This code will
              expire in 10 minutes.
            </p>
            <p className="verification__copy">
              <a href="#" onClick={resend} className="verification__link"> Resend the code </a>
            </p>
          </div>
        </div>
      }

      <div className="row">
        <div className="verification__subsection col-xs-10 col-sm-6">
          <h2 className="verification__subhed">
          { phoneNumber ? 'Trouble with Your Number?' : 'We Need Your Phone Number to Verify Your Account'}
          </h2>
          {
            phoneNumber &&
            <p className="verification__copy">
              <a href="#" onClick={showPhoneNumberForm} className="verification__link">
                Change your phone number
              </a>
            </p>
          }

          {
            isChangingNumber &&
            <Form
              validateError={phoneErrorValidator}
              onSubmit={changePhoneNumber}
              defaultValues={{
                phone: phoneNumber,
              }}
            >
              { (formApi) => (
                <form id="change-number" className={className} onSubmit={formApi.submitForm}>
                  <div className="row">
                    <div className="KS_input verification__input col-xs-8 col-sm-8">
                      <Text className={shouldShowError('phone', formApi) ? 'error' : ''} field="phone"
                            id="phone" placeholder="Enter your phone number" />
                    </div>
                  </div>
                  <div className="row center-xs pd-15">
                    <Button type="submit">{ phoneNumber ? 'Change' : 'Set' } Number</Button>
                  </div>
                </form>
              )}
            </Form>
          }
        </div>
      </div>
    </div>
  );
}


PhoneVerification.propTypes = {
  className: PropTypes.string,
  back: PropTypes.func,
  verify: PropTypes.func,
  resend: PropTypes.func,
  changePhoneNumber: PropTypes.func,
  phoneNumber: PropTypes.string,
  isChangingNumber: PropTypes.bool,
};

PhoneVerification.defaultProps = {
  className: '',
  back: () => null,
  verify: () => null,
  resend: () => null,
  changePhoneNumber: () => null,
  phoneNumber: '',
  isChangingNumber: false,
};

const mapStateToProps = state => ({
  phoneNumber: state.app.phoneNumber,
  isChangingNumber: state.app.isChangingNumber,
});

const mapDispatchToProps = (dispatch) => {
  return {
    verify: formValues => dispatch(verifyNumber(formValues)),
    resend: () => dispatch(resendCode()),
    showPhoneNumberForm: () => dispatch(showPhoneNumberForm()),
    changePhoneNumber: ({ phone }) => dispatch(changePhoneNumber({ phone })),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);
