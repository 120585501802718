const initialState = {
  token: null,
  loggedInUser: {},
  currentPage: 'login',
  isLoggedIn: false,
  isInitialized: false,
  phoneNumber: '',
  isChangingNumber: false,
  notification: {
    show: false,
    type: null,
    message: '',
  },
  appCues: {},
};

export default function appState(state = initialState, action = {}) {
  switch (action.type) {
    case 'APP_INITIALIZED':
      return Object.assign({}, state, {
        isInitialized: action.payload,
      });
    case 'SELECT_PAGE':
      return Object.assign({}, state, {
        currentPage: action.payload,
      });
    case 'UPDATE_JWT':
      return Object.assign({}, state, {
        token: action.payload,
      });
    case 'UPDATE_LOGGED_IN_USER':
      return Object.assign({}, state, {
        loggedInUser: action.payload,
        isLoggedIn: true,
      });
    case 'LOGOUT_USER':
      return Object.assign({}, state, {
        loggedInUser: {},
        isLoggedIn: false,
      });
    case 'SET_NUMBER_CHANGE_ATTEMPT':
      return Object.assign({}, state, {
        isChangingNumber: true,
      });
    case 'RESET_NUMBER_CHANGE_ATTEMPT':
      return Object.assign({}, state, {
        isChangingNumber: false,
      });
    case 'SET_NUMBER':
      return Object.assign({}, state, {
        phoneNumber: action.payload,
      });
    case 'UPDATE_NOTIFICATION':
      return Object.assign({}, state, {
        notification: {
          ...state.notification,
          ...action.payload,
        },
      });
    case 'APP.UPDATE_APP_CUES':
      return Object.assign({}, state, {
        appCues: action.payload,
      });
    default:
      return state;
  }
}
