import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import signup from '../../actions/signup';

import StepOne from './StepOne';
import StepTwo from './StepTwo';


class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      formValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        channel: '',
        password: '',
        confirm_password: '',
        mac: props.mac,
        site: props.site,
        ap: props.ap,
        url: props.url,
      }
    }

    this.startSignup = this.startSignup.bind(this);
    this.completeSignup = this.completeSignup.bind(this);
  }

  // completes step 2 (phone number collection) and creates trial signup
  completeSignup({ phone }) {
    const formValues = Object.assign({}, this.state.formValues, {
      phone,
    });

    this.props.signup(formValues);
  }

  // collects initial form values and advances to step 2 of signup process
  startSignup(formValues) {
    this.setState({
      ...this.state,
      step: 2,
      formValues: {
        ...this.state.formValues,
        ...formValues,
      }
    });
  }

  render() {
    const {
      className,
      back,
      mac,
      site,
      ap,
      url
    } = this.props;

    const { step } = this.state;

    if (step === 2) {
      return (
        <StepTwo
          className={className}
          back={back}
          signup={this.completeSignup}
          mac={mac}
          site={site}
          ap={ap}
          url={url}
        />
      );
    }

    return (
      <StepOne
        className={className}
        back={back}
        signup={this.startSignup}
        mac={mac}
        site={site}
        ap={ap}
        url={url}
      />
    );
  }
}


SignupForm.propTypes = {
  className: PropTypes.string,
  back: PropTypes.func,
  signup: PropTypes.func,
};

SignupForm.defaultProps = {
  className: '',
  back: () => null,
  signup: () => null,
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: formValues => dispatch(signup(formValues)),
  }
};

export default connect(null, mapDispatchToProps)(SignupForm);
