import axios from 'axios';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { sendSuccessNotification, sendErrorNotification } from './app';

export function guestSignup(formValues) {
  return (dispatch) => {
    dispatch(showLoading());

    const {
      first_name,
      last_name,
      email,
      company_name,
      here_to_see,
      mac,
      site,
      ap,
      url,
    } = formValues;

    const signupData = {
      first_name,
      last_name,
      email,
      company_name,
      here_to_see,
      mac,
      site,
      ap,
    };

    return axios.request({
      url: '/guest-signup',
      method: 'POST',
      data: signupData,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(hideLoading());
        dispatch(sendSuccessNotification('Sign Up successful! Redirecting in 5 seconds.'));

        const redirect = 'https://kettlespace.com?checkin=true';
        setTimeout(() => {
          window.location.replace(redirect); // eslint-disable-line
        }, 5000);
      })
      .catch((err) => {
        console.error(err);
        const errMsg = err.response ? err.response.data.message : 'Sign Up Failed! Try again';
        dispatch(hideLoading());
        dispatch(sendErrorNotification(errMsg));
      });
  };
}
