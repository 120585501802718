import React, { Component } from 'react';
import { connect } from 'react-redux';

import './style.css';
import Button from '../../components/Button';
import Notice from '../../components/Notice';
import PhoneVerification from '../../components/PhoneVerification';
import SignupForm from '../../components/SignupForm';
import GuestForm from '../../components/GuestForm';
import PasswordReset from '../../components/PasswordReset';
import LoginForm from '../../components/LoginForm';

import * as appActions from '../../actions/app';

// <p>Don't have a KettleSpace account?</p>
// <div className="alternate-buttons">
//   <Button onClick={() => select('trial-signup')} small className="mg-r-15">Start a trial</Button>
//   <Button onClick={() => select('guest-signup')} secondary small>Guest Login</Button>
// </div>

const Main = ({select, mac, ap, url, site, authorize}) => {
  return (
    <div>
      <div className="row login-wrapper">
        <div className="col-sm-6 actions">
          <LoginForm
            select={select}
            onSubmit={authorize}
            mac={mac}
            ap={ap}
            url={url}
            site={site}
          />
        </div>
        <div className="col-sm-6 welcome">
          <div className="overlay" />
          <div className="content">
            <h2>
            Welcome Back!
            </h2>
            <p>
            We're here to make sure you have a safe
            and productive return to work.
            </p>
            <p>
            If you have any questions or concerns,
            please reach out to the onsite community
            manager for assistance.
            </p>
          </div>
        </div>
      </div>
      <div className="center-xs pd-t-10 pd-b-5">
        By continuing you are agreeing to our <a rel="noopener noreferrer" target="_blank" href="https://www.kettlespace.com/terms-of-service">terms of service</a>.
      </div>
    </div>
  )
};

const Guest = ({ mac, ap, url, site, back, authorize, select }) => {
  return (
    <div>
      <div className="row login-wrapper">
        <div className="col-sm-6 actions">
          <LoginForm
            select={select}
            onSubmit={authorize}
            mac={mac}
            ap={ap}
            url={url}
            site={site}
            back={back}
            guest
          />
        </div>
        <div className="col-sm-6 actions bg-blue">
          <GuestForm
            mac={mac}
            ap={ap}
            url={url}
            site={site}
            trial
            cta="Connect as Guest"
          />
        </div>
      </div>
    </div>
  )
}

class LoginPage extends Component {
  constructor() {
    super();
    document.title = 'Wifi | KettleSpace';
  }

  render() {
    const query = new URLSearchParams(this.props.location.search);
    const mac = query.get('id');
    const ap = query.get('ap');
    const url = query.get('url');
    const site = this.props.match.params.site;

    const { currentPage, selectPage } = this.props;

    return (
      <div className="LoginPage container">
        {
          currentPage === 'login' && <Main
            authorize={this.props.authorize}
            mac={mac}
            ap={ap}
            url={url}
            site={site}
            select={selectPage}
          />
        }
        {
          currentPage === 'trial-signup' && <SignupForm
            mac={mac}
            ap={ap}
            url={url}
            site={site}
            className="pd-10"
            trial
            cta="Start Trial"
            back={() => selectPage('login')}
          />
        }
        {
          currentPage === 'guest-signup' && <Guest
            mac={mac}
            ap={ap}
            url={url}
            site={site}
            className="pd-10"
            trial
            cta="Connect"
            back={() => selectPage('login')}
            authorize={this.props.authorizeGuestPass}
            select={selectPage}
          />
        }
        {
          currentPage === 'notice' && <Notice
            className="pd-10"
            back={() => selectPage('login')}
          />
        }
        {
          currentPage === 'verify-phone' &&
          <PhoneVerification
            className="pd-10"
            back={() => selectPage('login')}
          />
        }
        {
          currentPage === 'password-reset' &&
          <PasswordReset
            back={() => selectPage('login')}
            resetPassword={(formData) => {
              this.props.resetPassword(formData);
              selectPage('login');
            }}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isVerifying: state.app.isVerifying,
  isAuthorizing: state.app.isAuthorizing,
  currentPage: state.app.currentPage,
})

const mapDispatchToProps = (dispatch) => {
  return {
    authorize: ({email, password, mac, site, ap, url}) => {
      dispatch(appActions.authorize(email, password, mac, site, ap, url));
    },
    authorizeGuestPass: ({ email, password, mac, site, ap, url}) => {
      dispatch(appActions.authorizeGuestPass(email, password, mac, site, ap, url));
    },
    resetPassword: ({email}) => {
      dispatch(appActions.initiatePasswordReset(email));
    },
    selectPage: page => dispatch(appActions.selectPage(page)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
