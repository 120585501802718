import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const Button = ({ isLoading, to, href, className, secondary, small, disabled, children, ...rest}) => {
  const classStyle = (className || '') + ' ' + ( disabled ? 'disabled' : (secondary ? 'secondary' : 'primary'));
  const classSize = small ? 'small' : '';
  return (
    to ? <Link className={`Button ${classStyle} ${classSize}`} to={to}>{children}</Link> : (
    href ?
      <a className={`Button ${classStyle} ${classSize}`} href={href} {...rest}>{children}</a>
      :
      (
        <button className={`Button ${classStyle} ${classSize}`} {...rest}>
          {isLoading ? loadingSpinner : children}
        </button>
      )
  ))
};

const loadingSpinner = (
  <div className="spinner">
    <i className="fa fa-spinner fa-spin fa-fw" />
    <span className="sr-only">Loading...</span>
  </div>
);

export default Button;
